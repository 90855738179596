/*-----------------------------
    Portfolio Details Styles 
-------------------------------*/
html {
    scroll-behavior: smooth;
    background-color: #101010;;
}
br{
    display:block !important;
}

.container {
    @media #{$small-mobile} {
        width: 100%;
        padding: 0 35px;
    }   
}


.coaching {
    .header-wrapper {
        padding: 30px 0 !important;
        
        &.sticky {
            padding: 20px 0 !important;
        }
    }

    .logo {
        max-width: 80px !important;
    }

    .header-mobile {
        p {
        color: #1f1f25;
        font-size: 15px;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 0;
        // z-index: 999;
            @media #{$sm-layout} {
                font-size: 13px !important;
            }    
        }  
        .header-title {
            font-size: 18px;
            font-weight: 500;
            font-family: 'Montserrat', sans-serif;
            @media #{$sm-layout} {
                font-size: 13px;
            }    
        }
    }

    .header-mobile {
        display: none;
    }

    .header--fixed {
        &.sticky {
            .header-title {
                .header-contact-link{
                    color: #1f1f25;
                    span {
                        // color: $life-coach-color;
                        color: #1f1f25;
                        font-weight: 800;
                    }
                }
            }
            .header-mobile {
                padding-left: 50px;
                display: inline-block;
                @media #{$mdplus-layout} {
                    padding-left: 80px;
                } 
            }
        }
    }

    .slide {
        .inner {
            @media #{$extra-device}, #{$laptop-device} {
                padding-top: 0;
            } 
            @media #{$smlg-device} {
                // width: 30%;
                padding-top: 150px;
            }
        }
        &.slider-style-3 {
            .inner {
                h1 {
                    &.life-coach-title {
                        span{
                            color: $life-coach-color;
                        }
                    }
                }
            }
        }
    }

    &.title{
        bottom: 20px;
    }
    .life-coach-title-small {
        font-size: 30px;
    }
    .contact-form--1 {
        margin-bottom: 70px;
    }

    .section-title {
        margin-top: 0;
        @media #{$sm-layout} {
            margin-bottom: 20px;
        } 
    }

    .service-area {
        @media #{$sm-layout} {
            padding-top: 0 !important;
        }  
    }

    .testimonials-area {
        @media #{$sm-layout} {
            padding: 120px 0 !important;
        } 
    }
    .rn-testimonial-content {
        .inner {
            max-width: 70%;
            margin: 0 auto;
            @media #{$sm-layout} {
                max-width: 100%;
            }
            &::after {
                // bottom: 0;
                top: 20px;
            } 
        }
    }

    .about-inner {
        .section-title {
            .life-coach-title {
                // color: red;
                font-size: 30px;
            }
            .description {
                padding-top: 20px;
                font-weight: 300;
            }
            p{
                a{
                    &:hover{
                        color: $life-coach-color;
                    }
                }
            }
        }
    }
    

    ul {
        &.social-share {
            li{
                &:hover{
                    a {
                        background: $life-coach-color;
                        border-color: $life-coach-color;
                    }
                }
            }
            &.color-theme{
                li {
                    a {
                        background: $life-coach-color;
                        color: $life-coach-color;
                        &:hover {
                            background: $life-coach-color;
                            border-color: $life-coach-color;
                        }
                    }
                }
            }
        }
    }

}

.life-coach-title {
    color: #101010;
    bottom: 20px;
}

.coach-subtitle {
    margin: 0 auto;
    position: relative;
    text-align: center;
    max-width: 70%;
    font-size: 30px;
    margin-bottom: 20px;
}

.life-coach-values-title {
    color: #101010;
    margin-bottom: 10px !important;
}

.life-coach-contact-form {
    .section-title {
     p {
        a:hover {
            color: $life-coach-color;
        }
     }
    }
}