/*-----------------------------
    Portfolio Details Styles 
-------------------------------*/

.bg_image--fa {
    background-image:url(/assets/images/portfolio/FA/FA_Preview.jpg);
    background-size: contain;
}

.bg_image--rover {
    background-image:url(/assets/images/portfolio/Rover/Rover_03.jpg);
    background-size: cover;
}

.bg_image--dogbuddy {
    background-image:url(/assets/images/portfolio/DogBuddy/DogBuddy_01.jpg);
    background-size: cover;
}

.bg_image--wi {
    background-image:url(/assets/images/portfolio/WorseIdeas/WorseIdeas_Preview.jpg);
    background-size: contain;
}

.portfolio-thumb-inner {
    .thumb {
        position: relative;
        .video-popup {
            position: absolute;
            top: 50%;
            left: 50%;
            border: 0 none;
            width: 110px;
            height: 110px;
            background: $theme-color;
            margin-left: -35px;
            margin-top: -35px;

            @media #{$sm-layout} {
                width: 70px;
                height: 70px;
            }
            
            &::before {
                border-left: 15px solid #ffffff;
            }
            span {
                font-size: 18px;
                line-height: 28px;
                display: inline-block;
                color: #292642;
                font-weight: 500;
            }
            &:hover {
                transform: scale3d(1.15, 1.15, 1.15);
            }
        }
    }
}

.portfolio-details {
    .inner {
        padding: 0 210px;
        @media #{$lg-layout} {
            padding: 0 100px;
        }
        @media #{$md-layout} {
            padding: 0 50px;
        }
        @media #{$sm-layout} {
            padding: 0 30px;
        }
        @media #{$large-mobile} {
            padding: 0px;
        }
        h3 {
            color: $body-color;
        }
        h2 {
            color: $body-color;
            line-height: 50px;
            display: block;
            margin-bottom: 0;
            padding-bottom: 24px;
            border-bottom: 1px solid #e1e1e1;
        }
        .extended-title {
            border: none;
            padding-bottom: 0;
        }
        .extended-subtitle {
            line-height: 50px;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            color: rgba(29, 29, 36, 0.75);
            margin-bottom: 30px;
            
            @media #{$lg-layout} {
                margin-bottom: 22px;
            }

            @media #{$md-layout} {
                margin-bottom: 22px;
            }

            @media #{$sm-layout} {
                margin-bottom: 22px;
            }
            &.subtitle {
                color: $body-color;
                font-size: 23px;
                line-height: 44px;
                margin-top: 28px;

                @media #{$lg-layout} {
                    font-size: 20px;
                    line-height: 38px;
                    margin-top: 24px;
                }
                @media #{$md-layout} {
                    font-size: 18px;
                    line-height: 38px;
                    margin-top: 24px;
                }
                @media #{$sm-layout} {
                    font-size: 18px;
                    line-height: 38px;
                    margin-top: 24px;
                }
            }
        }
        .project-role {
            margin-bottom: 15px;
        }
        .portfolio-view-list {
            margin: 0 -40px;
            .port-view {
                margin: 0 40px;
                span {
                    font-size: 16px;
                    color: rgba(29, 29, 36, 0.75);
                    display: block;
                    margin-bottom: 10px;
                    font-weight: 500;
                }
                h4 {
                    color: rgba(29, 29, 36, 0.75);
                    font-size: 22px;
                    font-weight: 500;
                }
            }
        }
    }
}

.portfolio-details-extended {
    margin-top: 40px;
    @media #{$md-layout} {
        padding-top: 64px;
    }
    @media #{$sm-layout} {
        padding-top: 40px;
    }
    @media #{$large-mobile} {
        padding-top: 40px;
    }
}



// Related Project
.portfolio-related-work {
    h2 {
        color: $body-color;
    }
    .related-work{
        .thumb{
            background-color: none;
            overflow: hidden;
            &:hover {
                background-color: #f6004c;
                border-radius: 6px;
            }
            a{
                img{
                    width: 100%;
                    &:hover{
                        object-fit: cover;
                        opacity: 0.8;
                    }
                }
            }
        }
        .inner {
            padding-top: 25px;
            h4 {
                font-size: 24px;
                line-height: 39px;
                margin-bottom: 7px;
                a {
                    color: $body-color;
                    @extend %transition;
                }
                &:hover{
                    a{
                        color: $theme-color;
                    }
                }
            }
            span {
                &.category {
                    color: rgba(29, 29, 36, 0.75);
                    font-size: 16px;
                    font-weight: 500;
                }
            }
        }
    }
}
